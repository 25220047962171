import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { AuthService } from './features/auth/services/auth.service';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { SessionTimeoutDialogComponent } from './shared/components/session-timeout-dialog/session-timeout-dialog.component';
import { LocalStorageService } from './shared/services/local-storage.service';

const MILLISECONDS_IN_SECOND = 1000;
const TEN_SECONDS = 10 * MILLISECONDS_IN_SECOND;
const SECONDS_IN_MINUTE = 60;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'seamfix';
  isSessionExpiredDialogOpen: boolean = false;
  private checkInterval: ReturnType<typeof setInterval> | null = null;

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.translate.use(this.localStorageService.getLanguage());
  }
  ngOnInit(): void {
    this.checkInterval = setInterval(() => {
      this.checkSession();
    }, TEN_SECONDS);
  }

  ngOnDestroy(): void {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  private checkSession(): void {
    const accessToken = this.authService.getJwtToken();
    const refreshToken = this.authService.getRefreshToken();

    if (accessToken && this.isTokenExpired(accessToken) && refreshToken) {
      const remainingTime = this.getRemainingTime(refreshToken);
      if (remainingTime <= SECONDS_IN_MINUTE && !this.isSessionExpiredDialogOpen) {
        this.openSessionExpiredDialog();
      }
    }
  }

  private openSessionExpiredDialog() {
    this.isSessionExpiredDialogOpen = true;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      data: {
        content: SessionTimeoutDialogComponent,
        contentData: {},
        hideCloseIcon: true,
        hideActionButtons: true,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isSessionExpiredDialogOpen = false;
    });
  }

  private isTokenExpired(token: string): boolean {
    try {
      const decodedToken: any = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / MILLISECONDS_IN_SECOND);
      return decodedToken.exp <= currentTime;
    } catch (error) {
      return true;
    }
  }

  private getRemainingTime(refreshToken: string): number {
    try {
      const decodedToken: any = jwtDecode(refreshToken);
      const currentTime = Math.floor(Date.now() / MILLISECONDS_IN_SECOND);
      return decodedToken.exp - currentTime;
    } catch (error) {
      return 0;
    }
  }
}

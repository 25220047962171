import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationsService } from '../../features/applications/services/applications.service';
import { LoggedInUserService } from '../../features/auth/services/logged-in-user.service';
import { ClaimNameEnum } from '../../features/user-management/enums/claim-module.enum';
import { MetaRequest } from '../../shared/models/meta.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private $pendingRequestsNoSubject: BehaviorSubject<number>;
  public $pendingRequestsNo: Observable<number>;

  constructor(
    private applicationsService: ApplicationsService,
    private loggedInUserService: LoggedInUserService
  ) {
    this.$pendingRequestsNoSubject = new BehaviorSubject<number>(0);
    this.$pendingRequestsNo = this.$pendingRequestsNoSubject.asObservable();

    if (!this.currentNotificationCountValue) {
      this.updatePendingRequestsNoFromAPI();
    }
  }

  updatePendingRequestsNoFromAPI(): void {
    this.loggedInUserService
      .userHasClaim(ClaimNameEnum.APPLICATION_REQUESTS_READ)
      .subscribe((hasClaim) => {
        if (hasClaim) {
          const meta = new MetaRequest();
          this.applicationsService
            .getAccessRequests(meta)
            .subscribe((accessRequestsResponse) =>
              this.updatePendingRequestsNo(accessRequestsResponse.summaryCards[1].value)
            );
        }
      });
  }

  updatePendingRequestsNo(pendingRequestsNo: number): void {
    this.$pendingRequestsNoSubject.next(pendingRequestsNo);
  }

  get currentNotificationCountValue(): number {
    return this.$pendingRequestsNoSubject.value;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { ClaimMenuItemEnum } from '../../../../features/user-management/enums/claim-module.enum';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { NavItem } from '../../../model/nav-item.model';
import { NotificationService } from '../../../services/notification.service';
import { WorkflowNotificationService } from '../../../services/workflow-notification.service';

@Component({
  selector: 'app-sub-item',
  templateUrl: './sub-item.component.html',
  styleUrls: ['./sub-item.component.scss'],
})
export class SubItemComponent extends BaseComponent implements OnInit {
  @Input() item!: NavItem;
  @Output() itemSelected = new EventEmitter<void>();

  isSelected = false;
  pendingAccessRequests = 0;
  pendingWorkflowNotifications = 0;

  constructor(
    private router: Router,
    private workflowNotificationService: WorkflowNotificationService,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.checkActiveRoute();
    });

    this.checkActiveRoute();
    this.getWorkflowNotifications();
    this.getAccessRequestNotifications();
  }

  getWorkflowNotifications() {
    this.workflowNotificationService.$workflowProcessNotifications
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const matchingNotification = res?.find(
          (notification) => notification.process_group === this.item.processGroup
        );

        this.pendingWorkflowNotifications = matchingNotification?.count ?? 0;

        this.item.notificationsCount =
          this.pendingWorkflowNotifications + this.pendingAccessRequests;
      });
  }

  getAccessRequestNotifications() {
    if (this.showAccessRequestsPendingCount) {
      this.notificationService.$pendingRequestsNo
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.pendingAccessRequests = res;

          this.item.notificationsCount =
            this.pendingWorkflowNotifications + this.pendingAccessRequests;
        });
    }
  }

  get showAccessRequestsPendingCount() {
    return this.item.claimMenuItems?.includes(ClaimMenuItemEnum.ADMIN_APPLICATIONS_ACCESS_REQUESTS);
  }

  selectItem() {
    this.isSelected = true;
    this.itemSelected.emit();
  }

  checkActiveRoute() {
    if (this.item.link) {
      this.isSelected = this.router.isActive(this.item.link, false);
    }
  }
}

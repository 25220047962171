<nav class="sidenav" [ngClass]="{ collapsed: isCollapsed }">
  <ul class="sidenav-list">
    <button class="collapse-btn" (click)="toggleCollapse()">
      <span class="material-icons">
        {{ isCollapsed ? 'menu' : 'menu_open' }}
      </span>
    </button>

    <app-nav-item
      [loggedInUserClaims]="loggedInUserClaims"
      *ngFor="let item of navMenuItems | navigationPipe: loggedInUserClaims"
      [item]="item"
      [isCollapsed]="isCollapsed"
      (expandTriggered)="removeCollapse()"
      [level]="0"
    ></app-nav-item>
  </ul>

  <img class="logo-powerd-by" src="../../../assets/logo/logo-powerd-by.png" />
</nav>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  MetaRequest,
  TableRows,
  TableRowsWithSummaryCards,
  TableRowsWithSummaryCardsResponse,
} from '../../../shared/models/meta.model';
import {
  AccessRequest,
  AccessRequestDetails,
  AccessRequestForm,
  AccessRequestStatusEnum,
  MyAccessRequest,
} from '../model/access-request.model';
import { ApplicationMetadata } from '../model/application-metadata.model';
import {
  Application,
  ApplicationStatusEnum,
  PredefinedApplication,
} from '../model/application.model';
import { AdditionalAuthenticationFactor, ApplicationDto } from '../model/create-application-model';
import { ImportUsersResponse } from '../model/impoet-users-response.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  constructor(private http: HttpClient) {}

  getAccessRequests(
    meta: MetaRequest,
    selectedStatusFilter: AccessRequestStatusEnum[] = []
  ): Observable<TableRowsWithSummaryCards<AccessRequest>> {
    return this.http
      .get<TableRowsWithSummaryCardsResponse<AccessRequest>>(
        `${environment.api}/application-requests`,
        {
          params: {
            ...meta,
            application_request_status: selectedStatusFilter,
          },
        }
      )
      .pipe(
        map((res) => ({
          meta: res.meta,
          data: res.data,
          summaryCards: [
            {
              label: 'accessRequest.totalNumber',
              value: res.summaryCard['total_num_of_requests'],
              type: 'info',
              cardTooltip: 'accessRequest.totalNumberTooltip',
            },
            {
              label: 'accessRequest.pendingRequests',
              value: res.summaryCard['num_of_pending_requests'],
              type: 'info',
              cardTooltip: 'accessRequest.pendingRequestsTooltip',
            },
            {
              label: 'accessRequest.approvedRequest',
              value: res.summaryCard['num_of_approved_requests'],
              type: 'info',
              cardTooltip: 'accessRequest.approvedRequestTooltip',
            },
            {
              label: 'accessRequest.rejectedRequests',
              value: res.summaryCard['num_of_rejected_requests'],
              type: 'info',
              cardTooltip: 'accessRequest.rejectedRequestsTooltip',
            },
          ],
        }))
      );
  }

  getMyAccessRequests(
    meta: MetaRequest,
    selectedStatusFilter: AccessRequestStatusEnum[] = []
  ): Observable<TableRowsWithSummaryCards<MyAccessRequest>> {
    return this.http
      .get<TableRowsWithSummaryCardsResponse<MyAccessRequest>>(
        `${environment.api}/application-requests/my-requests`,
        {
          params: {
            ...meta,
            application_request_status: selectedStatusFilter,
          },
        }
      )
      .pipe(
        map((res) => ({
          meta: res.meta,
          data: res.data,
          summaryCards: [
            {
              label: 'accessRequest.pendingRequests',
              value: res.summaryCard['num_of_pending_requests'],
              type: 'info',
              cardTooltip: 'accessRequest.pendingRequestsTooltip',
            },
          ],
        }))
      );
  }

  getMyAccessRequestById(id: string): Observable<AccessRequestDetails> {
    return this.http.get<AccessRequestDetails>(
      `${environment.api}/application-requests/my-request/${id}`
    );
  }

  createAccessRequest(accessRequestForm: AccessRequestForm): Observable<AccessRequest> {
    const formData = new FormData();

    if (accessRequestForm.application_id) {
      formData.append('application_id', accessRequestForm.application_id.toString());
    }

    if (accessRequestForm.group_id) {
      formData.append('group_id', accessRequestForm.group_id.toString());
    }

    if (accessRequestForm.category) {
      formData.append('category', accessRequestForm.category);
    }

    if (accessRequestForm.user_ids && accessRequestForm.user_ids?.length > 0) {
      accessRequestForm.user_ids.forEach((id, index) => {
        formData.append(`user_ids[${index}]`, id.toString());
      });
    }

    if (accessRequestForm.upload_approval) {
      formData.append('upload_approval', accessRequestForm.upload_approval);
    }

    return this.http.post<AccessRequest>(`${environment.api}/application-requests`, formData);
  }

  uploadApplicationLogo(logo: File): Observable<{ logoKey: string; logoUrl: string }> {
    const formData = new FormData();
    formData.append('logo', logo);
    return this.http.post<{ logoKey: string; logoUrl: string }>(
      `${environment.api}/applications/logo`,
      formData
    );
  }

  exportAccessRequestPDF(
    meta: MetaRequest,
    selectedStatusFilter: AccessRequestStatusEnum[] = []
  ): Observable<Blob> {
    return this.http.get(`${environment.api}/application-requests/pdf`, {
      responseType: 'blob',
      params: {
        search: meta.search,
        order: meta.order,
        application_request_status: selectedStatusFilter,
      },
    });
  }

  exportAccessRequestXLSX(
    meta: MetaRequest,
    selectedStatusFilter: AccessRequestStatusEnum[] = []
  ): Observable<Blob> {
    return this.http.get(`${environment.api}/application-requests/xlsx`, {
      responseType: 'blob',
      params: {
        search: meta.search,
        order: meta.order,
        application_request_status: selectedStatusFilter,
      },
    });
  }

  exportPDF(
    meta: MetaRequest,
    selectedStatusFilter: ApplicationStatusEnum[] = [],
    dateFilters?: { creationDateFrom?: string; creationDateTo?: string }
  ): Observable<Blob> {
    return this.http.get(`${environment.api}/applications/pdf`, {
      responseType: 'blob',
      params: {
        search: meta.search,
        order: meta.order,
        status: selectedStatusFilter,
        ...dateFilters,
      },
    });
  }

  exportXLSX(
    meta: MetaRequest,
    selectedStatusFilter: ApplicationStatusEnum[] = [],
    dateFilters?: { creationDateFrom?: string; creationDateTo?: string }
  ): Observable<Blob> {
    return this.http.get(`${environment.api}/applications/xlsx`, {
      responseType: 'blob',
      params: {
        search: meta.search,
        order: meta.order,
        status: selectedStatusFilter,
        ...dateFilters,
      },
    });
  }

  rejectRequest(id: string, comment: string): Observable<void> {
    return this.http.patch<void>(`${environment.api}/application-requests/${id}/reject`, {
      comment,
    });
  }

  resolveRequest(id: string): Observable<void> {
    return this.http.patch<void>(`${environment.api}/application-requests/${id}/resolve`, null);
  }

  updateRequest(id: string, group_id: number, user_ids?: number[]): Observable<void> {
    return this.http.patch<void>(`${environment.api}/application-requests/${id}`, {
      group_id,
      user_ids,
    });
  }

  getAccessRequestById(id: string): Observable<AccessRequestDetails> {
    return this.http.get<AccessRequestDetails>(`${environment.api}/application-requests/${id}`);
  }

  getApplications(
    meta?: MetaRequest,
    selectedStatusFilter: ApplicationStatusEnum[] = [],
    dateFilters?: { creationDateFrom?: string; creationDateTo?: string }
  ): Observable<TableRows<Application>> {
    return this.http.get<TableRows<Application>>(`${environment.api}/applications`, {
      params: {
        ...meta,
        ...dateFilters,
        status: selectedStatusFilter,
      },
    });
  }

  getAdminApplications(meta?: MetaRequest): Observable<TableRows<Application>> {
    return this.http.get<TableRows<Application>>(`${environment.api}/applications`, {
      params: {
        ...meta,
      },
    });
  }

  getMyApplications(meta: MetaRequest): Observable<Application[]> {
    return this.http.get<Application[]>(`${environment.api}/applications/my-applications`, {
      params: {
        search: meta.search,
      },
    });
  }

  getPredefinedApplications(): Observable<PredefinedApplication[]> {
    return this.http.get<PredefinedApplication[]>(`${environment.api}/applications/predefined`);
  }

  createApplication(application: ApplicationDto): Observable<ApplicationDto> {
    const {
      id,
      created_at,
      logoUrl,
      status,
      linkedApplications,
      provisioningSettings,
      predefinedApplication,
      jwksUri,
      oidcAuthorizeUrl,
      ...createApplicationParams
    } = application;
    this.setOidcNumberValues(createApplicationParams);
    createApplicationParams.loginFlow = this.removeEmptyAuthenticationFactor(
      createApplicationParams.loginFlow
    );
    return this.http.post<ApplicationDto>(
      `${environment.api}/applications`,
      createApplicationParams
    );
  }

  updateApplication(appId: number, application: ApplicationDto): Observable<ApplicationDto> {
    const {
      id,
      created_at,
      logoUrl,
      status,
      linkedApplications,
      provisioningSettings,
      predefinedApplication,
      jwksUri,
      oidcAuthorizeUrl,
      workflow_process_id,
      workflow_process_status,
      ...updateApplicationParams
    } = application;

    this.setOidcNumberValues(updateApplicationParams);
    updateApplicationParams.loginFlow = this.removeEmptyAuthenticationFactor(
      updateApplicationParams.loginFlow
    );
    return this.http.put<ApplicationDto>(
      `${environment.api}/applications/${appId}`,
      updateApplicationParams
    );
  }

  private removeEmptyAuthenticationFactor(
    additionalAuthenticationFactors?: AdditionalAuthenticationFactor[]
  ): AdditionalAuthenticationFactor[] {
    return (
      additionalAuthenticationFactors?.filter(
        (factor) => factor != AdditionalAuthenticationFactor.NONE
      ) ?? []
    );
  }

  private setOidcNumberValues(createApplicationParams: ApplicationDto) {
    if (createApplicationParams.ssoProtocolConfig.oidc) {
      const oidcConfig = createApplicationParams.ssoProtocolConfig.oidc;
      oidcConfig.accessTokenExpiry = Number(oidcConfig.accessTokenExpiry);
      oidcConfig.refreshTokenExpiry = Number(oidcConfig.refreshTokenExpiry);
    }
  }

  getApplicationById(id: number): Observable<ApplicationDto> {
    return this.http.get<ApplicationDto>(`${environment.api}/applications/${id}`);
  }

  getPredefinedApplicationById(id: number): Observable<ApplicationDto> {
    return this.http.get<ApplicationDto>(`${environment.api}/applications/predefined/${id}`);
  }

  getSamlMetadata(): Observable<ApplicationMetadata> {
    return this.http.get<ApplicationMetadata>(`${environment.api}/applications/get-saml-metadata`);
  }

  deactivateApplication(applicationId: number): Observable<void> {
    return this.http.patch<void>(
      `${environment.api}/applications/${applicationId}/deactivate`,
      null
    );
  }

  activateApplication(applicationId: number): Observable<void> {
    return this.http.patch<void>(`${environment.api}/applications/${applicationId}/activate`, null);
  }

  deleteApplication(applicationId: number): Observable<void> {
    return this.http.delete<void>(`${environment.api}/applications/${applicationId}`);
  }

  getSSOLink(applicationId: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(
      `${environment.api}/applications/${applicationId}/saml-sso-url`
    );
  }

  importUsers(applicationId: number): Observable<ImportUsersResponse> {
    return this.http.put<ImportUsersResponse>(
      `${environment.api}/applications/${applicationId}/import`,
      null
    );
  }
}

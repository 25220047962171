import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../features/auth/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getJwtToken();
    const isEnvUrl = req.url.includes(environment.api);

    // Check if the request has the 'Bypass-Interceptor' header
    const bypassInterceptor = req.headers.has('Bypass-Interceptor');

    // If bypass is requested, remove the header and skip adding the token
    if (bypassInterceptor) {
      const modifiedReq = req.clone({
        headers: req.headers.delete('Bypass-Interceptor'),
      });
      return next.handle(modifiedReq);
    }

    // Add token only if it's an environment API URL and the token exists
    if (token && isEnvUrl) {
      const authReq = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
      return next.handle(authReq);
    }

    // Proceed without modification if no conditions are met
    return next.handle(req);
  }
}

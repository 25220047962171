<div
  class="nav-item"
  [ngClass]="{
    expanded: isExpanded,
    selected: isSelected || isChildSelected,
    collapsed: isCollapsed,
  }"
  [matTooltip]="isCollapsed ? (item.name | translate) : null"
  matTooltipPosition="right"
>
  <div class="nav-link-wrapper">
    <a
      *ngIf="!item.children"
      [routerLink]="item.link"
      routerLinkActive="active-link"
      class="nav-link"
      [class.selected]="isSelected || isChildSelected"
      [ngClass]="{ 'tour-highlighted': tourService.isStepHighlighted(item.tourStepIndex) }"
      (click)="selectItem()"
    >
      <span class="material-icons nav-icon">{{ item.icon }}</span>
      <span class="nav-text" *ngIf="!isCollapsed">{{ item.name | translate }}</span>
    </a>
    <div
      *ngIf="item.children"
      class="nav-link expandable"
      (click)="toggleExpand()"
      [ngClass]="{ 'tour-highlighted': tourService.isStepHighlighted(item.tourStepIndex) }"
    >
      <div class="icon-badge-container">
        <span class="material-icons nav-icon">{{ item.icon }}</span>

        <span *ngIf="subItemWorkflowNotificationCount" class="pending-count-badge">
          {{ subItemWorkflowNotificationCount }}
        </span>
      </div>
      <span class="nav-text" *ngIf="!isCollapsed">{{ item.name | translate }}</span>

      <span class="material-icons expand-icon">
        {{ isExpanded ? 'expand_less' : 'expand_more' }}
      </span>
    </div>
  </div>
  <ul *ngIf="item.children" class="child-items" [hidden]="!isExpanded || isCollapsed">
    <app-sub-item
      *ngFor="let child of item.children | navigationPipe: loggedInUserClaims"
      [item]="child"
      (itemSelected)="handleItemSelected()"
    ></app-sub-item>
  </ul>
</div>

<a
  [routerLink]="item.link"
  routerLinkActive="active-link"
  class="nav-link"
  [class.selected]="isSelected"
  (click)="selectItem()"
>
  <span *ngIf="item.notificationsCount" class="pending-count-badge">
    {{ item.notificationsCount }}
  </span>

  <span class="nav-text">{{ item.name | translate }}</span>
</a>
